import React, {useCallback, useState} from 'react';
import {ClientsComponent} from "./ClientsComponent";
import {useQueryWithAccessToken} from "../../../../hooks/TanstackHooks";
import {cacheKeys} from "../../../../services/shared/serviceConstants";
import {useComponentForQueryResult} from "../../../../hooks/UseComponentForQueryResult";
import {getAllClients} from "../../../../services/Clients/ClientsQueries";
import {DefaultTableContainer} from "../../../../components/common/DefaultTable";
import {Box} from "@mui/material";

export const ClientsPanel = ({ userRole }:{ userRole: string }) => {
  const documentTitle = 'Clients - Detexian';
  document.title = documentTitle;

  const [userErrorMessage, setUserErrorMessge] = useState('');

  const clientsQueryResult = useQueryWithAccessToken({
      queryKey: [cacheKeys.clients],
      queryFunction: getAllClients,
      queryFunctionVariables: {},
      tanstackOptions:{
        onError: () => {
          setUserErrorMessge('error accessing available companies for logged in user');
        }
      },
      userRole
    }
  );

  // builds the successful page for the hook below
  const pageLoadFunction = useCallback(
    () =>
      <ClientsComponent
        userRole={userRole}
        clients={clientsQueryResult.data}
      />
    ,
    [ clientsQueryResult.data, userRole]
  );

  return <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      minWidth: '1000px',
    }}
  >
    <DefaultTableContainer
      shouldUseTableContainer={false}
    >
      {useComponentForQueryResult({
        queryResult: clientsQueryResult,
        pageLoadFunction,
        userErrorMessage,
        errorMessageDoesIndicateError: true
      })}
    </DefaultTableContainer>
  </Box>
};