import {doAuthenticatedQuery} from "src/services/shared/doQuery";
import {portalUserRoles} from "../../utils/constants";
import {
  createClientMutation,
  deleteClientMutation,
  getAllClientsQuery,
  getAllClientsWithFeaturesQuery,
  updateClientMutation
} from "./ClientsGraphQL";
import {ICompany} from "../../types/Company";
import {graphQLClient} from "../shared/GraphQLService";
import {transformCompanyAccess} from "../shared/ClientService";

export const getAllClients = async  ({accessToken, userRole}:{
    accessToken: any,
    userRole: string
  }
) : Promise<ICompany[]> => {
  console.log('getAllClients', {accessToken, userRole})
  const theQuery = async (client: any) => {
    const result = client.request(
      getAllClientsQuery,
      {
      },
      {
        'x-hasura-role': userRole ?? portalUserRoles.user,
      },
    );
    return result;
  }
  let response;
  try {
    response = await doAuthenticatedQuery(
      theQuery, accessToken
    );
  }
  catch (error) {
    console.error('getCompanies failed')
    throw (error)
  }

  return response.client.map((c:any) => {
    return {
      id: c.pub_id,
      name: c.name,
      slug: c.slug,
      planType: c.plan_type,
      disabled: c.is_disabled,
    }
  });
}

export const getAllClientsWithFeatures = async  ({accessToken, userRole}:{
    accessToken: any,
    userRole: string
}
) : Promise<ICompany[]> => {
  console.log('getAllClients', {accessToken, userRole})
  const theQuery = async (client: any) => {
    const result = client.request(
      getAllClientsWithFeaturesQuery,
      {
      },
      {
          'x-hasura-role': userRole ?? portalUserRoles.user,
      },
    );
    return result;
  }
  let response;
  try {
    response = await doAuthenticatedQuery(
      theQuery, accessToken
    );
  }
  catch (error) {
    console.error('getAllClientsWithFeatures failed')
    throw (error)
  }

  return response.client.map((c:any) => {
    return transformCompanyAccess(c);
  });
}

export const createClient = async (
  parameters: any,
) => {
  const {
    auth0HasuraAccessToken,
    client_id,
    client,
    role
  } = parameters;

  if (!client) throw new Error('client required');

  const gqlClient = await graphQLClient({accessToken: auth0HasuraAccessToken});

  if (role === portalUserRoles.admin) {
    gqlClient.setHeader("x-hasura-role", role);
  }

  const variables = {
    client_id: client_id,
    client: {
      name: client.name,
      plan_type: client.planType,
    }
  };

  const result = await gqlClient?.request(
    createClientMutation,
    variables
  )
  return result;
}

export const updateClient = async (
  parameters: any,
) => {
  const {
    auth0HasuraAccessToken,
    client_id,
    client,
    role
  } = parameters;

  if (!client) throw new Error('client required');

  const gqlClient = await graphQLClient({accessToken: auth0HasuraAccessToken});

  if (role === portalUserRoles.admin) {
    gqlClient.setHeader("x-hasura-role", role);
  }

  const variables = {
    client_id: client_id,
    client: {
      id: client.id,
      is_disabled: client.disabled,
      plan_type: client.planType,
    }
  };

  const result = await gqlClient?.request(
    updateClientMutation,
    variables
  )
  return result;
}

export const deleteClient = async (
  parameters: any,
) => {
  const {
    auth0HasuraAccessToken,
    client_id,
    client,
    role
  } = parameters;

  if (!client) throw new Error('client required');

  const gqlClient = await graphQLClient({accessToken: auth0HasuraAccessToken});

  if (role === portalUserRoles.admin) {
    gqlClient.setHeader("x-hasura-role", role);
  }

  const variables = {
    client_id: client_id,
    req: {
      client_id: client.id
    }
  };

  const result = await gqlClient?.request(
    deleteClientMutation,
    variables
  )
  return result;
}