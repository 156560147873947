// import {IActivityLog, IActivityLogResponse} from 'src/types/ActivityLog';

import {
  transformConnectedSaasFlat, transformUser
} from "../shared/sharedTransforms";

export const activityLogSubjects = {
  privilegeChange: 'PRIVILEGE_CHANGE',
  statusChange: 'STATUS_CHANGE',
  mfaChange: 'MFA_CHANGE',
  newUser: 'NEW_USER',
  userActivity: 'USER_ACTIVITY',
  appStatusChange: 'APP_STATUS_CHANGE',
  federationStatusChange: 'FEDERATION_STATUS_CHANGE',
}

const nicifySubject = (aSubject: any) => {
  switch (aSubject) {
    case(activityLogSubjects.privilegeChange):
      return 'Privilege';
    case(activityLogSubjects.statusChange):
      return 'Status';
    case(activityLogSubjects.mfaChange):
      return 'MFA'
    case(activityLogSubjects.newUser):
      return 'New User'
    case(activityLogSubjects.userActivity):
      return 'User Activity'
    case(activityLogSubjects.federationStatusChange):
      return 'Federation Status'
    default: return aSubject;
  }
}

export const transformDrift = ({
  pub_id,
  client_saas,
  generic_user,
  subject,
  old_value,
  new_value,
  drift_time
// }: IActivityLogResponse): IActivityLog => ({
}: any) => {
  return {
    driftId: pub_id,
    pubId: generic_user.pub_id,
    saas: transformConnectedSaasFlat(client_saas.saas),
    user: transformUser(generic_user),
    subject: nicifySubject(subject),
    previous: old_value,
    current: new_value,
    driftTime: drift_time,
  }
};
